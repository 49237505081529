import React from "react";
import Arrowcircle from "assets/icons/arrowcircle";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import Button from "atom/Button";
import Anchor from "atom/Anchor";
import style from "./style.scss";
import cn from "classnames";
import { Icon } from "../Facts";

const Download = ({
  title,
  subtitle,
  desktop,
  mobile,
  image,
  body,
  reverse,
  warm,
  header,
  icon,
}) => (
  <PageCenter
    rootClass={cn(warm ? style.warm : null)}
    contentClass={cn(style.content)}
  >
    {header && (
      <Heading style={{ marginBottom: 44 }} centered>
        {header}
      </Heading>
    )}
    <div className={cn(style.top, reverse && style.reverse)}>
      <div className={cn(style.text)}>
        {icon && <Icon icon={"arrowcircle"} />}
        <Heading>{title}</Heading>
        {subtitle && (
          <label className={cn(style.subtitle)} type={"h5"} weight={500}>
            {subtitle}
          </label>
        )}
        <div className={cn(style.buttons)}>
          <Anchor
            href={
              desktop.pdf +
              "?utm_source=optumconversation&utm_medium=weblink&utm_campaign=internal-traffic&utm_id=optumconversation-homepage"
            }
            download={true}
            target="_blank"
          >
            <Button>{desktop.label}</Button>
          </Anchor>
          {mobile && (
            <Anchor
              href={
                mobile.pdf +
                "?utm_source=optumconversation&utm_medium=weblink&utm_campaign=internal-traffic&utm_id=optumconversation-homepage"
              }
              download={true}
              target="_blank"
            >
              <Button>{mobile.label}</Button>
            </Anchor>
          )}
        </div>
      </div>
      <img {...image} />
    </div>
    {body.map(({ title, subtitle }, i) => (
      <div className={cn(style.body)} key={title + i}>
        {title ? (
          <Heading type={"h2"} className={cn(style.title)}>
            {title}
          </Heading>
        ) : null}
        <p>{subtitle}</p>
      </div>
    ))}
  </PageCenter>
);

export default Download;
