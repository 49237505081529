import React from "react";
import Anchor from "atom/Anchor";
import Button from "atom/Button";
import PageCenter from "atom/PageCenter";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Banner = ({ image, title, subtitle, button, reverse }) => (
  <PageCenter rootClass={cn(style.root)} contentClass={cn(style.container)}>
    <div className={cn(style.banner, reverse && style.reverse)}>
      <div className={cn(style.text)}>
        <Heading>{title}</Heading>
        {subtitle && <label>{subtitle}</label>}
        {button && (
          <Anchor
            href={
              button.pdf +
              "?utm_source=optumconversation&utm_medium=weblink&utm_campaign=internal-traffic&utm_id=optumconversation-homepage"
            }
          >
            <Button>{button.label}</Button>
          </Anchor>
        )}
      </div>
      <div className={cn(style.imgdiv)} />
      <ProgressiveImage {...image} />
    </div>
  </PageCenter>
);

export default Banner;
