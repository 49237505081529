export default {
  banner: {
    title: "Help parents talk about mental health with their teens",
    // button: {
    //   label: "Learn more",
    //   pdf: require("assets/Talking_about_mental_health_4_tips_for_parents_of_teens_and_young_adults_en-US.pdf"),
    // },
    image: {
      src: require("assets/banner_youth.png"),
      placeholder: require("assets/banner_youth_thumb.png"),
      height: 336,
      width: 736,
    },
  },
  facts: {
    title: "We can't ignore the facts.",
    items: [
      {
        icon: "alert",
        label: "1 in 5 children",
        sublabel: "have a mental health condition each year.",
      },
      {
        icon: "brain",
        label: "50%",
        sublabel: "of mental illnesses begin by age 14.",
      },
      {
        icon: "whistle",
        label: "More than 20%",
        sublabel:
          "of children reported worsened mental health during the pandemic.",
      },
      {
        icon: "mask",
        label: "53%",
        sublabel:
          "of working parents have missed work at least once per month to deal with their child's mental health.",
      },
    ],
  },
  download: {
    header: "Youth conversation cards",
    title: "Start the conversation",
    desktop: {
      label: "Download for Desktop",
      pdf: "/Optum_EAP_ConversationStarters_Desktop.pdf",
    },
    mobile: {
      label: "Download for Mobile",
      pdf: "/Optum_EAP_ConversationStarters_Mobile.pdf",
    },
    body: [
      {
        title: "Spark a conversation",
        subtitle:
          "If you’re wondering how to get started, and get past one-word answers, these conversation starters can help. Click either of the above buttons to download a digital deck of 30 conversation starters.\n\n You can print this shuffle deck out and use it around the kitchen table, in the car or anywhere you want to strike up a conversation with a teen or young adult, by clicking the printer icon in the top/right corner of your browser window once you have clicked through to the next screen.\n\n Or, you can download this PDF to your computer or phone, by clicking download/save icon in the top/right corner of your browser window once you have clicked through to the next screen.",
      },
    ],
    image: {
      src: require("assets/download.png"),
      height: 380,
      width: 379,
    },
  },
  article: {
    image: {
      src: require("assets/article_youth.png"),
      placeholder: require("assets/article_youth_thumb.png"),
      height: 416,
      width: 592,
    },
    title: "Talking about mental health with teens",
    subtitle:
      "For some families, talking about mental health may be an everyday occurrence. For other families, it may be a new topic, and it may feel a little awkward at first.\n\nHowever, the more you talk about it, the more comfortable it will feel — for you and for them.",
    link: {
      label: "Read article >",
      pdf: "/Talking_about_mental_health_4_tips_for_parents_of_teens_and_young_adults_en-US.pdf",
    },
  },
};
