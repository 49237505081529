export default {
  banner: {
    title: "Helping foster parents and their kids connect.",
    image: {
      src: require("assets/banner_foster.jpg"),
      placeholder: require("assets/banner_foster_thumb.jpg"),
      height: 336,
      width: 736,
    },
  },
  facts: {
    title: "We can be the solution",
    items: [
      {
        icon: "alert",
        label: "Over 437,000",
        sublabel: "children and youth are in foster care.",
      },
      {
        icon: "brain",
        label: "Nearly 50%",
        sublabel:
          "of all homes licensed to provide foster care quit within their first year due to burnout.",
      },
      {
        icon: "question",
        label: "What’s missing?",
        sublabel:
          "Mental and behavioral health is the largest unmet health need for youth in foster care.",
      },
      {
        icon: "heartcare",
        label: "Feeling valued.",
        sublabel:
          "Celebrating milestones and creating traditions can help foster children feel valued and special.",
      },
    ],
  },
  download: {
    header: "Foster care conversation cards",
    title: "Start the conversation",
    desktop: {
      label: "Download digital cards",
      pdf: "/FosterCareConversationCards_Digital.pdf",
    },
    mobile: {
      label: "Download print cards",
      pdf: "/FosterCareConversationCards_Print.pdf",
    },
    body: [
      {
        title: "Spark a conversation",
        subtitle:
          "One of the best ways to build trust in your household while helping a new child transition into your family is to get to know each other. Use these cards to start the conversation. They can be used in any order when the time is right for you and your child.\n\nMake sure to help the child understand that what they share is important. Also let them know the information is meant to help them as they adjust to becoming a member of your family.",
      },
    ],
    image: {
      src: require("assets/foster.png"),
      height: 380,
      width: 379,
    },
  },
  article: {
    image: {
      src: require("assets/article_caregiver.jpg"),
      placeholder: require("assets/article_caregiver_thumb.jpg"),
      height: 416,
      width: 592,
    },
    title: "How to help your foster child feel welcome and adjust",
    subtitle:
      "With an open heart, a desire to help and a belief that you have something meaningful to give, you’ve chosen to become a foster parent.\n\nHere are some tips to help you get ready for this important journey.",
    link: {
      label: "Read article >",
      pdf: "/How_to_help_your_foster_child_adjust_en-US.pdf",
    },
  },
};
