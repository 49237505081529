import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Anchor from "atom/Anchor";
import PageCenter from "atom/PageCenter";
import OptumLogo from "assets/optum-logo.svg";
import MenuIcon from "assets/menu.svg";
import CloseIcon from "assets/x.svg";
import PrimaryLinks from "../PrimaryLinks";
import Sidebar from "../Sidebar";
import style from "./style.scss";
import cn from "classnames";

const Header = ({ headerText, links = [] }) => {
  let location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <PageCenter rootClass={cn(style.root)} contentClass={cn(style.content)}>
      <Sidebar open={open} links={links} />
      <div className={cn(style.header)}>
        <div className={cn(style.menu)}>
          {open ? (
            <CloseIcon onClick={() => setOpen(false)} />
          ) : (
            <MenuIcon onClick={() => setOpen(true)} />
          )}
        </div>
        <div className={cn(style.logo)}>
          <NavLink to={"/"} end={true}>
            <OptumLogo />
          </NavLink>
          <label>{headerText}</label>
        </div>
      </div>
      <div className={cn(style.links)}>
        <PrimaryLinks links={links} />
      </div>
    </PageCenter>
  );
};

export default Header;
