import React from "react";
import { NavLink } from "react-router-dom";
import style from "./style.scss";
import cn from "classnames";

const PrimaryLinks = ({ links }) => {
  let activeClassName = cn(style.active);

  return (
    <div className={cn(style.content)}>
      {links.map(({ label, to }, i) => (
        <div key={i} className={cn(style.link)}>
          <NavLink
            to={to}
            end={to == "/" ? true : ""}
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            {label}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default PrimaryLinks;
